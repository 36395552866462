import { NavigateOptions, To, useNavigate } from "react-router-dom";
export function useNavigateKeepSearchParams() {
	const navigate = useNavigate();
	return (to: To, options?: NavigateOptions) => {
		const searchParams = new URLSearchParams(window.location.search);
		if (searchParams.size > 0) {
			console.log("searchParams", searchParams);
			// Create a new array from the searchParams and map over them
			const paramsString = Array.from(searchParams.entries())
				.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
				.join("&");
            // Add the search params to the new path
            to = `${to}?${paramsString}`;
		}
		console.log("to", to);
		navigate(to, options);
	};
}


export default useNavigateKeepSearchParams;