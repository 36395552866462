import { createContext, ReactNode, useState } from "react";

export type IHeaderContext = {
	isComingSoonOpen: boolean;
	setIsComingSoonOpen: (value: boolean) => void;
};

type IProps = {
	children: ReactNode;
};

export const HeaderContext = createContext<IHeaderContext>(undefined!);

export function HeaderProvider(props: IProps) {
	const [isComingSoonOpen, setIsComingSoonOpen] = useState<boolean>(true);

	return (
		<HeaderContext.Provider
			value={{
				isComingSoonOpen,
				setIsComingSoonOpen,
			}}>
			{props.children}
		</HeaderContext.Provider>
	);
}
