import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import classes from "./classes.module.scss";
import classNames from "classnames";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { container } from "tsyringe";
import FileService from "services/FileService";
import Button, { EButtonColor, EButtonVariant } from "components/elements/Button";
import LazyImage from "components/elements/LazyImage";
import DefaultAvatar from "assets/images/default-avatar.svg";
import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";
import { format } from "date-fns";
import I18nStore from "components/materials/I18n/I18nStore";
import { match } from "ts-pattern";
import StatusIcon from "components/materials/StatusIcon";
import { EProjectStatus } from "common/enums/Project/Status/EProjectStatus";
import Social from "components/materials/SocialButton";

type IProps = {
	isOpen: boolean;
	onClose: () => void;
	onChangeGeneratedPosterImage: () => void;
	canChangeGeneratedPosterImage: boolean;
	project: ProjectResponseResource | null;
};

const fileService = container.resolve(FileService);

function formatDate(date: Date): string {
	return format(date, "yyyy-MM-dd");
}

export default function Drawer(props: IProps) {
	const getPoster = () => {
		const folder = "POSTER";
		const fileId = props.project?.poster?.file?.id;
		if (!fileId) {
			console.error("No poster ID found");
			return null;
		}
		return fileService.getFileUrl(folder, fileId);
	};

	const getAvatar = () => {
		const fileUrl = props.project?.author?.avatar?.url;
		if (!fileUrl) {
			console.error("No avatar url found");
			return null;
		}
		return fileUrl;
	};

	const publicationDate = () => {
		const createdAt = props.project?.createdAt;
		if (!createdAt) return "";

		const locale = match(I18nStore.getInstance().lang)
			.with("fr", () => "fr-FR")
			.with("en", () => "en-US")
			.exhaustive();

		return new Date(formatDate(createdAt)).toLocaleDateString(locale, { day: "numeric", month: "long", year: "numeric" });
	};

	const getPitch = () => {
		return match(I18nStore.getInstance().lang)
			.with("fr", () => props.project?.pitch)
			.with("en", () => props.project?.pitch_en)
			.exhaustive();
	};

	return (
		<div className={classes["root"]}>
			<div
				className={classNames(classes["drawer"], {
					[classes["open"]!]: props.isOpen,
				})}>
				<div className={classes["close-container"]}>
					<XMarkIcon className={classes["close-icon"]} onClick={props.onClose} />
				</div>

				<div className={classes["header"]}>
					<div className={classes["poster-container"]}>
						{props.isOpen && <LazyImage src={getPoster()} alt="Poster" className={classes["poster"]} />}

						{props.canChangeGeneratedPosterImage && (
							<Button variant={EButtonVariant.TEXT} color={EButtonColor.PRIMARY} onClick={props.onChangeGeneratedPosterImage}>
								<ArrowPathIcon />
								<Typography type="p" size="large" weight="medium">
									{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.poster.regenerate)}
								</Typography>
							</Button>
						)}
					</div>

					<div className={classes["info-container"]}>
						<div className={classes["author-container"]}>
							<Typography type="p" size="medium" weight="bold">
								{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.author)}
							</Typography>

							<div className={classes["author"]}>
								{props.isOpen && <LazyImage src={getAvatar() ?? DefaultAvatar} alt="Avatar" className={classes["avatar"]} />}
								<div className={classes["user"]}>
									<Typography type="p" size="small" weight="bold">
										{props.project?.author.firstName} {props.project?.author.lastName}
									</Typography>
									<Typography type="p" size="small" weight="bold">
										{props.project?.author.email}
									</Typography>
									<Typography type="p" size="small" weight="bold">
										{props.project?.author.phoneNumber}
									</Typography>
								</div>
							</div>
						</div>

						<div className={classes["info"]}>
							<Typography type="p" size="medium" weight="bold">
								{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.infos.title)}
							</Typography>

							<Typography type="p" size="small" weight="regular">
								{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.infos.publication_date)} {publicationDate()}
							</Typography>

							<Typography type="p" size="small" weight="regular">
								{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.infos.genre)}{" "}
								{props.project && I18n.trslt(I18n.asset.enums.EProjectThemes[props.project.theme])}
							</Typography>

							<Typography type="p" size="small" weight="regular">
								{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.infos.format)}{" "}
								{props.project && I18n.trslt(I18n.asset.enums.EProjectFormats[props.project.format])}
							</Typography>

							<StatusIcon status={props.project?.status || EProjectStatus.STUDYING} withDescription />
						</div>
					</div>
				</div>

				<div className={classes["body"]}>
					<Typography type="h1" weight="bold">
						{props.project?.title}
					</Typography>
					<Typography type="p" size="medium" weight="regular">
						{getPitch()}
					</Typography>
				</div>

				{props.project && <Social project={props.project} />}
			</div>

			{props.isOpen && <div className={classes["overlay"]} onClick={props.onClose} />}
		</div>
	);
}
