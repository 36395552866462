import { singleton } from "tsyringe";
import BaseAdminApi from "./BaseAdminApi";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import ProjectUpdateStatusRequestResource from "common/resources/Project/ProjectUpdateStatusRequestResource";
import { EProjectStatus } from "common/enums/Project/Status/EProjectStatus";
import { Pagination } from "utils/Pagination";

@singleton()
export default class ProjectApi extends BaseAdminApi {
	private baseurl = `${this.apiUrl}/projects`;

	public async getProjects(pagination?: Pagination) {
		const query = pagination ? `?_take=${pagination.take}&_skip=${pagination.skip}` : "";

		return this.getRequest<ProjectResponseResource[]>(`${this.baseurl}${query}`);
	}

	public async countProjects() {
		return this.getRequest<{ total: number }>(`${this.baseurl}/count`);
	}

	public async getProjectByStatus(status: EProjectStatus, pagination?: Pagination) {
		const query = pagination ? `?_take=${pagination.take}&_skip=${pagination.skip}` : "";

		return this.getRequest<ProjectResponseResource[]>(`${this.baseurl}/status/${status}${query}`);
	}

	public async countProjectsByStatus(status: EProjectStatus) {
		return this.getRequest<{ total: number }>(`${this.baseurl}/count/status/${status}`);
	}

	public async getArchivedProjects(pagination?: Pagination) {
		const query = pagination ? `?_take=${pagination.take}&_skip=${pagination.skip}` : "";

		return this.getRequest<ProjectResponseResource[]>(`${this.baseurl}/archived${query}`);
	}

	public async countArchivedProjects() {
		return this.getRequest<{ total: number }>(`${this.baseurl}/count/archived`);
	}

	public async updateStatus(projectId: string, project: ProjectUpdateStatusRequestResource) {
		return this.putRequest<ProjectResponseResource>(`${this.baseurl}/${projectId}/status`, { ...project });
	}

	public async archive(projectId: string) {
		return this.putRequest<ProjectResponseResource>(`${this.baseurl}/${projectId}/archive`);
	}

	public async restore(projectId: string) {
		return this.putRequest<ProjectResponseResource>(`${this.baseurl}/${projectId}/restore`);
	}

	public async changeGeneratedPosterImage(projectId: string) {
		return this.postRequest<{ discarded: boolean } | { next: true }>(`${this.baseurl}/${projectId}/poster/change`);
	}

	public async canDiscardGeneratedPosterImage(projectId: string) {
		return this.getRequest<{ canDiscard: boolean }>(`${this.baseurl}/${projectId}/poster/can-discard`);
	}
}
