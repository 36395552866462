import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { container } from "tsyringe";
import ModulePage from "components/materials/ModulePage";
import ModuleConfig from "configs/ModuleConfig";
import Page404 from "components/pages/Page404";
import Home from "components/pages/Home";
import Register from "components/pages/Register";
import Login from "components/pages/Login";
import ForgotPassword from "components/pages/ForgotPassword";
import ResetPassword from "components/pages/ResetPassword";
import Contact from "components/pages/Contact";
import SubmitProject from "components/pages/SubmitProject";
import MyProjects from "components/pages/MyProjects";
import ConfirmationCode from "components/pages/ConfirmationCode";
import Profile from "components/pages/Profile";
import Projects from "components/pages/Projects";
import Subscribe from "components/pages/Subscribe";
import Project from "components/pages/Project";
import HasRules from "components/materials/HasRules";
import PrivacyPolicy from "components/pages/PrivacyPolicy";
import ScrollToTop from "components/elements/ScrollToTop";
import IsLogged from "components/materials/IsLogged";
import LegalNotice from "components/pages/LegalNotice";
import AdminDashboard from "components/pages/AdminDashboard";
import TermsOfUse from "components/pages/TermsOfUse";
import CompleteProfile from "components/pages/CompleteProfile";

const modules = container.resolve(ModuleConfig).get().modules;

const router = createBrowserRouter([
	{
		path: modules.pages.Home.props.path,
		element: (
			<ScrollToTop>
				<ModulePage from={modules.pages.Home} onDisabled={() => console.error("Home page disable")}>
					<Home />
				</ModulePage>
			</ScrollToTop>
		),
	},
	{
		path: modules.pages.Projects.props.path,
		element: (
			<ScrollToTop>
				<ModulePage from={modules.pages.Projects} onDisabled={() => console.error("Projects page disable")}>
					<Projects />
				</ModulePage>
			</ScrollToTop>
		),
	},
	{
		path: modules.pages.Project.props.path,
		element: (
			<ScrollToTop>
				<IsLogged
					onNotLogged={() => {
						const redirectUrl = new URLSearchParams(window.location.search).get("redirect");
						if (redirectUrl) return;
						router.navigate(modules.pages.Login.props.path.concat(`?redirect=${window.location.pathname}`), { replace: true });
					}}>
					<ModulePage from={modules.pages.Project} onDisabled={() => console.error("Project page disable")}>
						<HasRules requiredRules={{ AND: { access__project: true } }} onNoRules={() => router.navigate(modules.pages.ConfirmationCode.props.path)}>
							<Project />
						</HasRules>
					</ModulePage>
				</IsLogged>
			</ScrollToTop>
		),
	},
	{
		path: modules.pages.ConfirmationCode.props.path,
		element: (
			<ScrollToTop>
				<IsLogged onNotLogged={() => router.navigate(modules.pages.Login.props.path, { replace: true })}>
					<HasRules requiredRules={{ AND: { can__confirm_account: true } }} onNoRules={() => router.navigate(modules.pages.Home.props.path)}>
						<ModulePage from={modules.pages.ConfirmationCode} onDisabled={() => console.error("ConfirmationCode page disable")}>
							<ConfirmationCode />
						</ModulePage>
					</HasRules>
				</IsLogged>
			</ScrollToTop>
		),
	},
	{
		path: modules.pages.Contact.props.path,
		element: (
			<ScrollToTop>
				<ModulePage from={modules.pages.Contact} onDisabled={() => console.error("Contact page disable")}>
					<Contact />
				</ModulePage>
			</ScrollToTop>
		),
	},
	{
		path: modules.pages.Subscribe.props.path,
		element: (
			<ScrollToTop>
				<ModulePage from={modules.pages.Subscribe} onDisabled={() => console.error("Subscribe page disable")}>
					<Subscribe />
				</ModulePage>
			</ScrollToTop>
		),
	},
	{
		path: modules.pages.SubmitProject.props.path,
		element: (
			<ScrollToTop>
				<IsLogged onNotLogged={() => router.navigate(modules.pages.Login.props.path, { replace: true })}>
					<HasRules requiredRules={{ AND: { access__submit_project: true } }} onNoRules={() => router.navigate(modules.pages.ConfirmationCode.props.path)}>
						<ModulePage from={modules.pages.SubmitProject} onDisabled={() => console.error("SubmitProject page disable")}>
							<SubmitProject />
						</ModulePage>
					</HasRules>
				</IsLogged>
			</ScrollToTop>
		),
	},
	{
		path: modules.pages.Profile.props.path,
		element: (
			<ScrollToTop>
				<ModulePage from={modules.pages.Profile} onDisabled={() => console.error("Profile page disable")}>
					<HasRules requiredRules={{ AND: { access__my_profile: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<Profile />
					</HasRules>
				</ModulePage>
			</ScrollToTop>
		),
	},
	{
		path: modules.pages.CompleteProfile.props.path,
		element: (
			<ScrollToTop>
				<ModulePage from={modules.pages.Profile} onDisabled={() => console.error("Profile page disable")}>
					<HasRules requiredRules={{ AND: { access__my_profile: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<CompleteProfile />
					</HasRules>
				</ModulePage>
			</ScrollToTop>
		),
	},
	{
		path: modules.pages.MyProjects.props.path,
		element: (
			<ScrollToTop>
				<ModulePage from={modules.pages.MyProjects} onDisabled={() => console.error("MyProjects page disable")}>
					<IsLogged onNotLogged={() => router.navigate(modules.pages.Login.props.path, { replace: true })}>
						<HasRules requiredRules={{ AND: { access__my_projects: true } }} onNoRules={() => router.navigate(modules.pages.ConfirmationCode.props.path)}>
							<MyProjects />
						</HasRules>
					</IsLogged>
				</ModulePage>
			</ScrollToTop>
		),
	},
	{
		path: modules.pages.Register.props.path,
		element: (
			<ScrollToTop>
				<ModulePage from={modules.pages.Register} onDisabled={() => console.error("Register page disable")}>
					<Register />
				</ModulePage>
			</ScrollToTop>
		),
	},
	{
		path: modules.pages.Login.props.path,
		element: (
			<ScrollToTop>
				<ModulePage from={modules.pages.Login} onDisabled={() => console.error("Login page disable")}>
					<IsLogged onNotLoggedComponent={<Login />}>
						<Home />
					</IsLogged>
				</ModulePage>
			</ScrollToTop>
		),
	},
	{
		path: modules.pages.ForgotPassword.props.path,
		element: (
			<ScrollToTop>
				<ModulePage from={modules.pages.ForgotPassword} onDisabled={() => console.error("ForgotPassword page disable")}>
					<ForgotPassword />
				</ModulePage>
			</ScrollToTop>
		),
	},
	{
		path: modules.pages.ResetPassword.props.path,
		element: (
			<ScrollToTop>
				<ModulePage from={modules.pages.ResetPassword} onDisabled={() => console.error("ResetPassword page disable")}>
					<ResetPassword />
				</ModulePage>
			</ScrollToTop>
		),
	},
	{
		path: modules.pages.PrivacyPolicy.props.path,
		element: (
			<ScrollToTop>
				<ModulePage from={modules.pages.PrivacyPolicy} onDisabled={() => console.error("PrivacyPolicy page disable")}>
					<PrivacyPolicy />
				</ModulePage>
			</ScrollToTop>
		),
	},
	{
		path: modules.pages.TermsOfUse.props.path,
		element: (
			<ScrollToTop>
				<ModulePage from={modules.pages.TermsOfUse} onDisabled={() => console.error("TermsOfUse page disable")}>
					<TermsOfUse />
				</ModulePage>
			</ScrollToTop>
		),
	},
	{
		path: modules.pages.LegalNotice.props.path,
		element: (
			<ScrollToTop>
				<ModulePage from={modules.pages.LegalNotice} onDisabled={() => console.error("LegalNotice page disable")}>
					<LegalNotice />
				</ModulePage>
			</ScrollToTop>
		),
	},
	{
		path: modules.pages.AdminDashboard.props.path,
		element: (
			<ScrollToTop>
				<ModulePage from={modules.pages.AdminDashboard} onDisabled={() => console.error("AdminDashboard page disable")}>
					<AdminDashboard />
				</ModulePage>
			</ScrollToTop>
		),
	},
	{
		path: "*",
		element: <Page404 />,
	},
]);

export default function Routing() {
	return <RouterProvider router={router} />;
}

/*type IProps = {
	children: React.ReactNode;
	from: IModuleProps["from"];
};*/

/**
 * This component is used to check if the user is logged in or not.
 * If the user just logged in, it will redirect to the home page.
 * If the user just logged out, it will redirect to the login page.
 */
/*function PageMiddleware(props: IProps) {
	const navigate = useNavigate();
	const userContext = React.useContext(UserContext);
	const userId = React.useRef<{ id: string | null }>({ id: null });

	React.useEffect(() => {
		if (userContext.user && userId.current.id !== userContext.user?.id) {
			userId.current.id = userContext.user?.id;
			// just logged in
			if (userId.current.id) {
				navigate("/");
				return;
			}
			// just logged out
			if (!userId.current.id) {
				navigate(modules.pages.Login.props.path);
				return;
			}
		}
	}, [navigate, userContext]);

	return (
		<ModulePage from={props.from} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
			{props.children}
		</ModulePage>
	);
}
*/
