/* eslint-disable */
import EventService from "services/EventEmitter";
import fr from "configs/I18n/fr";
import en from "configs/I18n/en";
import { match } from "ts-pattern";

type IVars = { [key: string]: string };

export type ILngType = I18nStore["asset"];

const lngs = {
	fr,
	en,
};

type LngKeys = keyof typeof lngs;

function replaceAll(string: string, search: string, replace: string) {
	return string.split(search).join(replace);
}

export default class I18nStore {
	private static instance: I18nStore;
	private readonly event = new EventService();
	private static readonly defaultLng: LngKeys = "fr";
	private _assetDefault = lngs[I18nStore.defaultLng]!;
	private _asset = lngs[I18nStore.defaultLng]!;
	private cache = new Map<string, string | undefined>();

	private constructor() {
		this.autoDetectAndSetLanguage();
	}

	public static getInstance(): I18nStore {
		return (this.instance = I18nStore.instance ?? new I18nStore());
	}

	public get assetDefault() {
		return this._assetDefault;
	}

	public get asset() {
		return this._asset;
	}

	public get lang() {
		return (localStorage.getItem("lang") as LngKeys) ?? I18nStore.defaultLng;
	}

	public get locale() {
		return match(this.lang)
			.with("fr", () => "fr-FR")
			.with("en", () => "en-US")
			.exhaustive();
	}

	/**
	 * @returns removelistener callback
	 */
	public onChange(callback: (asset: ILngType) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public toggleTo(key: LngKeys) {
		this.switch(lngs[key]!, key);
		return this.asset;
	}

	public trslt(text: string, vars: IVars = {}) {
		const cacheKey = text.concat(JSON.stringify(vars));

		const value = this.getCache(cacheKey);

		if (value !== undefined) return value;

		// check if there is a variable that is not replaced
		const missingVariables = text.match(/{{(.*?)}}/g);
		if (missingVariables) {
			missingVariables.forEach((variable) => {
				const clearedVariable = variable.replace("{{", "").replace("}}", "");
				if (vars[clearedVariable] === "" || vars[clearedVariable] === undefined) console.error(`Missing variable in translation "${text}" : ${variable}`);
			});
		}

		const resultWithVariables = Object.keys(vars).reduce((str, key) => replaceAll(str, `{{${key}}}`, vars[key] ?? ""), text);
		this.setCache(cacheKey, resultWithVariables);
		return resultWithVariables;
	}

	private switch(asset: ILngType, key: string) {
		this.storeLang(key);
		if (asset === this._asset) return;
		this._asset = asset;
		// whenever the application's language changes the localization cache is fully cleared:
		this.cache.clear();

		this.event.emit("change", { lang: key, ...this._asset });
	}

	private getCache(key: string): string | undefined {
		return this.cache.get(key);
	}

	private setCache(key: string, value: string) {
		this.cache.set(key, value);
	}

	private autoDetectAndSetLanguage() {
		this.toggleTo(this.lang);
	}

	private storeLang(key: string) {
		const lng = localStorage.getItem("lang");
		if (!lng || lng !== key) localStorage.setItem("lang", key);
	}
}
